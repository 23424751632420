import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'


Vue.config.productionTip = false;

axios.interceptors.response.use(response => {
  return response;
}, error => {
  //add offline detection
  if (error.response.status === 403) {
    if(process.env.VUE_APP_DEBUG === "true")  console.log("user not authenticated");
    app.$store.commit('showLoginPopup');
  }
  return error;
});

axios.interceptors.request.use(
  config => {
    if(process.env.VUE_APP_DEBUG === "true") console.log("axio conf set");
    config.headers['X-Token'] = localStorage.token;
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  });

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted() {
    if(process.env.VUE_APP_DEBUG === "true") console.log("main.js mounted");
  }
}).$mount('#app')
