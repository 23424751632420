import Vue from 'vue'
import VueRouter from 'vue-router'
import Customers from '../views/Customers.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/kunden',
  },
  {
    path: '/kunden',
    name: 'Kunden',
    component: Customers
  },
  {
    path: '/wartungen',
    name: 'Wartungen',
    component: () => import('../views/Maintenance.vue')
  },
  {
    path: '/log',
    name: 'Log',
    component: () => import('../views/Log.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
