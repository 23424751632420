<template>
  <v-app id="wartung-management">
    <v-app-bar app dark dense>
      <v-btn icon>
        <v-icon app dark @click="doLogOut">mdi-logout</v-icon>
      </v-btn>
      <v-tabs id="nav-tabs" centered dark icons-and-text app dense>
        <v-tab to="/log">
          Log
          <v-icon>mdi-clipboard-list</v-icon>
        </v-tab>
        <v-tab to="/wartungen">
          Wartungen
          <v-icon>mdi-format-list-checks</v-icon>
        </v-tab>
        <v-tab to="/kunden">
          Kunden
          <v-icon>mdi-account-box-multiple</v-icon>
        </v-tab>
      </v-tabs>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="loginDialog"
      width="600px"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card elevation="2" max-width="600px" style="margin: auto">
        <v-toolbar flat>
          <v-toolbar-title>Login</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn text @click="closeLoginDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text
          >Bitte melden Sie sich an, um zugriff auf die Daten zu
          erhalten.</v-card-text
        >

        <v-form ref="form" @submit="doLogin" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              label="Benutzername"
              v-model="name"
              :rules="nameRules"
              id="username"
              required
            ></v-text-field>
            <v-text-field
              label="Passwort"
              v-model="password"
              :rules="passwordRules"
              id="password"
              type="password"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid"
              @click="validate"
              type="submit"
              block
              x-large
              color="primary"
            >
              Anmelden
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    userName: localStorage.userName || "",
    drawer: null,
    valid: true,
    name: "",
    nameRules: [(v) => !!v || "Bitte Namen eingeben"],
    password: "",
    passwordRules: [(v) => !!v || "Bitte Passwort eingeben"],
    snackbar: false,
    snackbarText: "",
    timeout: 3000,
  }),
  computed: {
    loginDialog: {
      get() {
        return this.$store.state.loginPopupOpen;
      },
      set(value) {
        this.$store.commit(value ? "showLoginPopup" : "hideLoginPopup");
      },
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    closeLoginDialog() {
      this.$store.commit("hideLoginPopup");
    },
    doLogOut: function (e) {
      if (confirm("Wollen Sie die Lokalen Anmeldedaten wirklich löschen?")) {
        axios.get(process.env.VUE_APP_API_PATH + "/user/logout");
        localStorage.token = "";
        localStorage.name = "";
      }
    },
    doLogin: function (e) {
      const t = this;
      e.preventDefault();
      if (this.valid) {
        axios
          .post(process.env.VUE_APP_API_PATH + "/user/login", {
            name: document.getElementById("username").value,
            password: document.getElementById("password").value,
          })
          .then(function (response) {
            if (response.status == 200) {
              if(process.env.VUE_APP_DEBUG === "true") console.log("login success");
              localStorage.token = response.data.token;
              localStorage.name = response.data.name;
              t.loginDialog = false;
              t.snackbarText = "Anmeldung erfolgreich";
              t.snackbar = true;
            } else {
              t.snackbarText = "Anmeldedaten sind nicht richtig.";
              t.snackbar = true;
            }
          }).catch(function(){
              t.snackbarText = "Server nicht erreichbar, sind Sie offline?";
              t.snackbar = true;
          });
      }
    },
  },
};
</script>

<style>
#nav-tabs {
  flex: auto !important;
}
header {
  background-color: primary;
}
.v-main__wrap {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
</style>