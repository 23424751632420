<template>
  <div class="customers">
    <v-container>
      <v-data-table
        :headers="headers"
        :items="customers"
        :custom-sort="danielsGeileCustomSortierung"
        :search="search"
        sort-by="name"
        class="elevation-1"
        :single-expand="true"
        :expanded.sync="expanded"
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [10, 50, 100, 250, -1],
          'items-per-page-text' : 'Zeige',
          'items-per-page-all-text': 'Alle'
        }"
        @item-expanded="loadExpanded"
        item-key="id"
        show-expand
      >
        <!-- Kunde bearbeiten -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-tabs v-model="tab" flat dense>
              <v-tab key="overview" href="#overview">
                <v-icon>mdi-star</v-icon>
                <span class="hide-on-mobile">Übersicht</span>
              </v-tab>
              <v-tab key="files" href="#files">
                <v-icon>mdi-file-multiple</v-icon>
                <span class="hide-on-mobile">Dateien</span>
              </v-tab>
              <v-tab key="edit" href="#edit">
                <v-icon>mdi-account-edit</v-icon>
                <span class="hide-on-mobile">Bearabeiten</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item key="overview" value="overview">
                <br />
                <div v-if="item.note">
                  <h5>Notiz</h5>
                  <p class="mb-3">{{ item.note }}</p>
                </div>
                <v-row v-if="item.phone || item.email" class="mb-3">
                  <v-col v-if="item.email" sm="6" cols="12" >
                    <v-btn
                      color="primary"
                      text
                      block
                      :href="'mailto:' + item.email"
                      target="_blank"
                    >
                      <v-icon>mdi-email</v-icon>
                      {{ item.email }}
                    </v-btn>
                  </v-col>
                  <v-col v-if="item.phone" sm="6" cols="12" >
                    <v-btn
                      color="primary"
                      text
                      block
                      :href="'tel:' + item.phone"
                      target="_blank"
                    >
                      <v-icon>mdi-phone</v-icon>
                      {{ item.phone }}
                    </v-btn>
                  </v-col>
                  <!-- <v-col v-if="item.phone" sm="4" cols="12" >
                    <v-btn
                      color="primary"
                      text
                      block
                      :href="whatsappLink(item)"
                      target="_blank"
                    >
                      <v-icon>mdi-whatsapp</v-icon>
                      {{ item.phone }}
                    </v-btn>
                  </v-col> -->
                </v-row>
                <div v-if="currentMaintenance">
                  <h5>Letzte Wartung</h5>
                  <maintenanceCard
                    :maintenance="currentMaintenance"
                    :customers="customers"
                  />
                  <br />
                </div>
                <br />
                <v-row>
                  <v-col
                    sm="6"
                    cols="12"
                    style="max-width: 400px; margin: auto"
                  >
                    <v-btn color="primary" block @click="showMaintenance(item)">
                      Letzte Wartungen anzeigen
                    </v-btn>
                  </v-col>
                  <v-col
                    sm="6"
                    cols="12"
                    style="max-width: 400px; margin: auto"
                  >
                    <v-btn
                      color="primary"
                      block
                      @click="openMapsLink(item)"
                      target="_blank"
                    >
                      <v-icon>mdi-map-search</v-icon>
                      auf Maps öffnen
                    </v-btn>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="files" value="files">
                <br />
                <v-row class="mb-4">
                  <v-col>
                    <v-file-input
                      show-size
                      dense
                      v-model="maintainencContractInput"
                      append-icon="mdi-upload"
                      label="Wartungsvertrag hochladen"
                      accept="image/*, application/pdf"
                      id="maintainencContractInput"
                      @click:append="uploadMaintainencContract(item)"
                    ></v-file-input>
                  </v-col>
                  <v-col
                    v-if="item.maintenance_contract_path"
                    lg="3"
                    md="4"
                    sm="6"
                    cols="12"
                    style="text-align: right"
                  >
                    <v-btn
                      color="primary"
                      :href="downloadUrl(item.maintenance_contract_path)"
                      target="_blank"
                    >
                      Vertrag herunterladen
                    </v-btn>
                  </v-col>
                </v-row>

                <fileComponent :parent="item" type="customerMedia" />
              </v-tab-item>
              <v-tab-item key="edit" value="edit">
                <br />
                <v-form
                  ref="editCustomerform"
                  id="editCustomerform"
                  v-model="editCustomerValid"
                  autocomplete="off"
                >
                  <customerComponent :key="forceReRender" :editCustomer="item"></customerComponent>

                  <v-row align="center" justify="space-around">
                    <v-col>
                      <v-btn
                        :disabled="!editCustomerValid"
                        color="primary"
                        @click="editCustomer(item)"
                      >
                        Speichern
                      </v-btn>
                    </v-col>
                    <v-col style="text-align: right">
                      <v-btn
                        color="red darken-1"
                        dark
                        @click="deleteCustomer(item)"
                      >
                        Löschen
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
            <br />
            <br />
            <br />
            <hr
              style="
                margin: 0 -15px;
                border: 0;
                border-bottom: thin solid rgba(0, 0, 0, 0.12);
              "
            />
          </td>
        </template>

        <!-- Header Btns -->
        <template v-slot:top>
          <v-toolbar flat fluid fill-height>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Suche"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col sm="auto">
                <v-select
                  v-model="selectedFilter"
                  :items="regionsFilter"
                  item-text="name"
                  item-value="id"
                  label="Regionen"
                  return-object
                  single-line
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>

        <template v-slot:item.markers="{ item }">
          <v-icon color="red" v-if="item.marked_red == 1" small
            >mdi-alert-circle</v-icon
          >
          <v-icon color="blue" v-if="item.marked_black == 1" small
            >mdi-star-circle</v-icon
          >
        </template>

        <template v-slot:item.phone="{ item }">
          <a :href="'tel:' + item.phone">{{ item.phone }}</a>
        </template>

        <!-- Footer btns -->
        <template v-slot:footer.page-text>

          <span style="margin-right: 15px;">
            von {{ customers.length }} Kunden 
          </span>
          <!-- Kunde Anlegen -->
          <v-dialog v-model="newCustomerDialog" max-width="750px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" text dark v-bind="attrs" v-on="on">
                Kunde hinzufügen
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Kunde Hinzufügen</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="newCustomerform"
                  id="newCustomerform"
                  v-model="newCustomerValid"
                  autocomplete="off"
                >
                  <customerComponent></customerComponent>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="closeNewCustomer">
                  Abbrechen
                </v-btn>
                <v-btn
                  :disabled="!newCustomerValid"
                  color="primary"
                  @click="addNewCustomer"
                >
                  Kunde hinzufügen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            text
            color="primary"
            dark
            class="ma-2"
            @click="loadCustomers(true)"
          >
            Neu laden
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
    <template>
      <div class="text-center">
        <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
          {{ snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn color="accent" text v-bind="attrs" @click="snackbar = false">
              Schließen
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </template>
  </div>
</template>



<script>
import axios from "axios";
import customerComponent from "@/components/Customer.vue";
import fileComponent from "@/components/Files.vue";
import maintenanceCard from "@/components/MaintenanceCard.vue";
import regions from "../regionen.json";

const defaultFilter = "* Alle Regionen";
const defaultNoFilter = "* Ohne Region";


export default {
  data: () => ({
    search: "",
    forceReRender: 1,
    selectedFilter: null,
    regionsFilter: [],

    newCustomerValid: false,
    newCustomerDialog: false,

    editCustomerValid: false,
    tab: null,

    dialogDelete: false,

    snackbar: false,
    snackbarText: "",
    snackbarTimeout: 3000,

    maintainencContractInput: null,

    currentMaintenance: null,

    expanded: [],
    customers: [],
  }),
  components: {
    customerComponent,
    fileComponent,
    maintenanceCard,
  },
  computed: {
    headers() {
      return [
        { text: "", value: "markers", sortable: false },
        { text: "Name", value: "name" },
        { text: "Ort", value: "city" },
        { text: "Straße", value: "street" },
        { text: "Letzte Wartung", value: "last_maintenance" },
        { text: "Nächste Wartung", value: "next_maintenance" },
        { text: "Region", value: "region", filter: this.filterRegion },
        { text: "Telefon", value: "phone",  align: ' d-none'  },
        { text: "Re. Adr.", value: "billing_address",  align: ' d-none'  },
        { text: "", value: "data-table-expand", sortable: false },
      ];
    },
  },

  watch: {
    newCustomerDialog(val) {
      val || this.closeNewCustomer();
    },
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      //let list = window.dataStore.regions.slice();
      let list = JSON.parse(JSON.stringify(regions)); //prevent refs

      //setup filter
      this.selectedFilter = defaultFilter;
      this.regionsFilter = list;
      this.regionsFilter.unshift(defaultNoFilter);
      this.regionsFilter.unshift(defaultFilter);
      this.loadCustomers(false);
    },
    loadExpanded(row) {
      if (row.value == false) {
        if(process.env.VUE_APP_DEBUG === "true") console.log("unset maintenance");
        this.currentMaintenance = null;
      } else {
        this.forceReRender++;
        if(process.env.VUE_APP_DEBUG === "true") console.log("load expanded");
        const t = this;
        axios
          .get(
            process.env.VUE_APP_API_PATH +
              "/data/maintenances?mode=latestForCustomer&cfid=" +
              row.item.id
          )
          .then(function (response) {
            if (response.status == 200) {
              if(process.env.VUE_APP_DEBUG === "true")
                console.log("customers maintenance loaded", response);
              t.currentMaintenance = response.data;
            }
          });
      }
    },
    uploadMaintainencContract(item) {
      if (this.maintainencContractInput) {
        const t = this;
        const formData = new FormData();
        formData.append("id", item.id);
        formData.append("type", "contract");
        formData.append("files[]", this.maintainencContractInput);
        axios
          .post(process.env.VUE_APP_API_PATH + "/data/media", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            if (response.status == 200) {
              if(process.env.VUE_APP_DEBUG === "true")
                console.log(
                  "maintainenc Contract upload successfull",
                  response
                );
              t.snackbarText = "Upload erfolgreich";
              item.maintenance_contract_path = response.data.files;
              t.maintainencContractInput = null;
            } else {
              t.snackbarText =
                "Upload fehlgeschlagen. Bitte später erneut versuchen";
            }
            t.snackbar = true;
          })
          .catch(function () {
            t.snackbarText = "Upload fehlgeschlagen.  Sind sie offline?";
            t.snackbar = true;
          });
      }
    },
    showMaintenance(customer) {
      this.$router.push({ path: "wartungen", query: { cfid: customer.id } });
    },
    openMapsLink(customer) {
      window
        .open(
          "https://maps.google.com/?q=" +
            encodeURIComponent(
              `${customer.street}, ${customer.postcode} ${customer.city}`
            ),
          "_blank"
        )
        .focus();
    },
    downloadUrl(mediaName) {
      return (
        process.env.VUE_APP_API_PATH +
        "/data/media/" +
        mediaName +
        "?t=" +
        encodeURIComponent(localStorage.token)
      );
    },
    loadCustomers(showMsg) {
      if (localStorage.customers) {
        this.customers = JSON.parse(localStorage.customers);
      }

      const t = this;
      axios
        .get(process.env.VUE_APP_API_PATH + "/data/customers")
        .then(function (response) {
          if (response.status == 200) {
            if(process.env.VUE_APP_DEBUG === "true")
              console.log("customers loaded", response);
            t.customers = response.data;
            localStorage.customers = JSON.stringify(response.data);
            if (showMsg) {
              t.snackbarText = "Kunden geladen";
              t.snackbar = true;
            }
          } else {
            t.snackbarText = "Kunden konnten nicht geladen werden";
            t.snackbar = true;
          }
        })
        .catch(function () {
          t.snackbarText = "Server nicht erreichbar. Sind sie offline?";
          t.snackbar = true;
        });
    },

    filterRegion(region) {
      return (
        this.selectedFilter == defaultFilter ||
        (this.selectedFilter == defaultNoFilter && !region) ||
        region == this.selectedFilter
      );
    },

    editCustomer(item) {
      const t = this;
      const formData = new FormData(
        document.getElementById("editCustomerform")
      );
      let plzCity = formData.get("plzCity").split(" - ");
      formData.append("postcode", plzCity[0]);
      formData.append("city", plzCity[1]);
      formData.delete("plzCity");
      axios
        .post(
          process.env.VUE_APP_API_PATH + "/data/updateCustomer/" + item.id,
          formData
        )
        .then(function (response) {
          if (response.status == 200) {
            if(process.env.VUE_APP_DEBUG === "true")
              console.log("customer updated", response);
            localStorage.customers = JSON.stringify(t.customers);
            t.snackbarText = "Kunde erfolgreich gespeichert";
          } else {
            t.snackbarText = "Kunden konnten nicht gespeichert werden!";
          }
          t.snackbar = true;
        })
        .catch(function () {
          t.snackbarText = "Server nicht erreichbar. Sind sie offline?";
          t.snackbar = true;
        });
    },

    deleteCustomer(item) {
      if (confirm("Kunde wirklich löschen?")) {
        const t = this;
        axios
          .delete(process.env.VUE_APP_API_PATH + "/data/customer/" + item.id)
          .then(function (response) {
            if (response.status == 200) {
              if(process.env.VUE_APP_DEBUG === "true")
                console.log("customer deleted", response);
              t.customers = t.customers.filter(function (current) {
                return current.id != item.id;
              });
              localStorage.customers = JSON.stringify(t.customers);
              t.snackbarText = "Kunde wurde gelöscht";
            } else {
              t.snackbarText = "Kunden konnten nicht gelöscht werden!";
            }
            t.snackbar = true;
          })
          .catch(function () {
            t.snackbarText = "Server nicht erreichbar. Sind sie offline?";
            t.snackbar = true;
          });
      }
    },

    closeNewCustomer() {
      this.newCustomerDialog = false;
    },
    // whatsappLink(item){
    //   if(item.)
    //   return 'https://wa.me/' + item.phone
    // },
    addNewCustomer() {
      this.$refs.newCustomerform.validate();
      const t = this;
      if (this.newCustomerValid) {
        const formData = new FormData(
          document.getElementById("newCustomerform")
        );
        let plzCity = formData.get("plzCity").split(" - ");
        formData.append("postcode", plzCity[0]);
        formData.append("city", plzCity[1]);
        formData.delete("plzCity");
        axios
          .post(process.env.VUE_APP_API_PATH + "/data/customer", formData)
          .then(function (response) {
            if (response.status == 200) {
              if(process.env.VUE_APP_DEBUG === "true")
                console.log("customers added", response);
              t.closeNewCustomer();
              t.customers.push(response.data);
              localStorage.customers = JSON.stringify(t.customers);
              t.$refs.newCustomerform.reset();
              t.snackbarText = "Kunde erfolgreich angelegt";
            } else {
              t.snackbarText = "Kunden konnten nicht angelegt werden!";
            }
            t.snackbar = true;
          })
          .catch(function () {
            t.snackbarText = "Server nicht erreichbar. Sind sie offline?";
            t.snackbar = true;
          });
      }
    },
    danielsGeileCustomSortierung(items, index, isDesc) {

      const sortCol = index[0];
      const desc = isDesc[0];
      const dateSort = sortCol === "last_maintenance" || sortCol === "next_maintenance";

      //console.log(sortCol, desc, dateSort);

      const sortFunction = function (objectA, objectB) {

          const a = objectA[sortCol];
          const b = objectB[sortCol];

          // if descending, highest sorts first
          if(dateSort){
              return desc ? Date.parse(b) - Date.parse(a) : Date.parse(a) - Date.parse(b);
          }
          return desc ? b.localeCompare(a) : a.localeCompare(b);
      };
      
      const sortableItems = [];
      const nullItems = [];

      items.forEach((element) => {
        if(element[sortCol]){
          sortableItems.push(element);
        }else{
          nullItems.push(element);
        }
      });

      sortableItems.sort(sortFunction);

      return sortableItems.concat(nullItems);
    },
  },
};
</script>

<style>
tr.v-data-table__mobile-table-row td.v-data-table__mobile-row:nth-last-child(2) {
    display: none;
}
.v-data-footer__pagination{
  margin-left: 0 !important;
}
.v-application--is-ltr .v-data-footer__select .v-select{
  margin-left: 15px !important;
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
  border-bottom: thick solid rgba(0, 0, 0, 0.12) !important;
}
.v-data-table--mobile .v-data-table__expanded__content > td {
  width: 100%;
  border: none;
  display: inline-table;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.v-data-table__expanded:not(:last-child)
  > td:last-child {
  border-bottom: none;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 30px;
}
.v-data-table--mobile .hide-on-mobile {
  display: none !important;
}

.v-data-table__expand-icon--active {
  background-color: #005eb6 !important;
  border-radius: 50%;
  color: white !important;
}
</style>