<template>
  <v-list-item three-line>
    <v-list-item-content>
      <div class="text-overline mb-0">
        {{ formatDate(maintenance.created) }}
      </div>
      <v-list-item-title class="text-h5 mb-1">
        {{ customerName(maintenance.customer_id) }}
        <div v-if="maintenance.street" class="addDiv">
          <small>{{ maintenance.street }}<br />
            {{ maintenance.postcode }} {{ maintenance.city }}</small>
        </div>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ maintenance.note }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

export default {
  name: "MaintenanceView",
  props: ["maintenance", "customers"],
  data: () => ({
    
    snackbar: null,
    snackbarText: "",
    snackbarTimeout: 3000
  }),
  // watch: {
  //   expanded(val) {
  //     if (val === true) {
  //       this.initDetails();
  //     }
  //   },
  // },
  components: {
  },
  methods: {
    customerName(customer_id) {
      // console.log(customer_id);
      // console.log(this.customers.find((c) => c.id == customer_id));
      const cus = this.customers.find((c) => c.id == customer_id);
      return cus ? cus.name : '';
    },
    formatDate(created) {
      return created.split(" ")[0];
    },
  },
  mounted() {},
};
</script>
<style scoped>
.addDiv{
  line-height: 0.8em;
  font-size: 0.8em;
  padding-bottom: 0.5em;
}
</style>
