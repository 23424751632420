<template>
  <div class="filesComp">
    <v-row v-if="!isUploading">
      <v-col>
        <v-file-input
          multiple
          show-size
          dense
          v-model="mediaInput"
          label="Medien hinzufügen"
          accept="image/*, application/pdf"
          id="mediaInput"
        ></v-file-input>
      </v-col>
      <v-col style="flex-grow: 0">
        <v-btn
          :disabled="this.mediaInput == null || this.mediaInput.length === 0"
          color="primary"
          @click="uploadCustomerMedia"
        >
          <v-icon>mdi-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="isUploading" style="text-align: center" class="mb-4">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <br />
    <v-row>
      <v-col
        v-for="currentMedia in images"
        v-bind:key="currentMedia.key"
        class="d-flex child-flex"
        cols="4"
        @click="showDetail(currentMedia)"
      >
        <div v-if="currentMedia.isImage">
          <v-img
            max-height="150"
            max-width="250"
            :src="currentMedia.url"
          ></v-img>
        </div>
        <div v-else class="placeholder">
          <v-img
            max-height="150"
            max-width="250"
            src="/img/placeholder.jpg"
          ></v-img>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      dark
      prominent
      transition="dialog-bottom-transition"
    >
      <v-card
        v-if="dialog"
        :style="`background-image: url('${
          currentDetail.isImage ? currentDetail.url : '/img/placeholder.jpg'
        }');
              background-position: center;
              background-size: ${currentDetail.isImage ? 'contain' : '50px'};`"
      >
        <v-toolbar dense>
          <v-toolbar-title>{{ currentDetail.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="deleteMedia(currentDetail)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn icon :href="currentDetail.url" download target="_blank">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>

    <template>
      <div class="text-center">
        <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
          {{ snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn color="accent" text v-bind="attrs" @click="snackbar = false">
              Schließen
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FileView",
  props: ["parent", "type"],
  data: () => ({
    mediaInput: null,
    isUploading: false,
    dialog: false,
    currentDetail: null,
    snackbar: false,
    snackbarText: "",
    snackbarTimeout: 3000,
  }),
  computed: {
    images() {
      let key = 1;
      const out = [];
      try {
        JSON.parse(this.parent.media).forEach((e) => {
          out.push({
            key: key++,
            name: e,
            url: this.getMediaUrl(e),
            isImage: this.isImage(e),
          });
        });
      } catch (e) {
        this.snackbarText = "Medien Informationen fehlerhaft";
        this.snackbar = true;
      }
      return out;
    },
  },
  methods: {
    showDetail(media) {
      this.currentDetail = media;
      this.dialog = true;
    },
    getMediaUrl(mediaName) {
      return (
        process.env.VUE_APP_API_PATH +
        "/data/media/" +
        mediaName +
        "?t=" +
        encodeURIComponent(localStorage.token)
      );
    },
    isImage(fileName) {
      return ["jpg", "jpeg", "jpe", "png"].includes(
        fileName.split(".").pop().toLowerCase()
      );
    },
    deleteMedia(media) {
      if(process.env.VUE_APP_DEBUG === "true") console.log(media);
      if (confirm("Datei löschen?")) {
        const t = this;
        axios
          .delete(
            process.env.VUE_APP_API_PATH +
              "/data/media/" +
              media.name +
              "?type=" +
              this.type +
              "&id=" +
              this.parent.id
          )
          .then(function (response) {
            if (response.status == 200) {
              if(process.env.VUE_APP_DEBUG === "true")
                console.log("media successfully deleted", response);
              t.snackbarText = "Gelöscht";
              t.parent.media = response.data.files;
              t.dialog = false;
            } else {
              t.snackbarText =
                "Löschen fehlgeschlagen. Bitte später erneut versuchen";
              t.snackbar = true;
            }
          })
          .catch(function () {
            t.snackbarText = "Löschen fehlgeschlagen. Sind sie offline?";
            t.snackbar = true;
          });
      }
    },
    uploadCustomerMedia() {
      if (!this.isUploading) {
        this.isUploading = true;
        const t = this;
        const formData = new FormData();
        formData.append("id", this.parent.id);
        formData.append("type", this.type);
        if(process.env.VUE_APP_DEBUG === "true") console.log(this.mediaInput);
        this.mediaInput.forEach((currentFile) => {
          formData.append("files[]", currentFile);
        });
        axios
          .post(process.env.VUE_APP_API_PATH + "/data/media", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            if (response.status == 200) {
              if(process.env.VUE_APP_DEBUG === "true")
                console.log("media upload successfull", response);
              t.parent.media = response.data.files;
              t.mediaInput = null;
              t.snackbarText = "Upload erfolgreich";
            } else {
              t.snackbarText =
                "Upload fehlgeschlagen. Bitte später erneut versuchen";
            }
            t.snackbar = true;
            t.isUploading = false;
          })
          .catch(function () {
            t.isUploading = false;
            t.snackbarText = "Upload fehlgeschlagen. Sind Sie offline?";
            t.snackbar = true;
          });
      }
    },
  },
  mounted() {},
};
</script>

<style>
.filesComp .placeholder .v-image__image--cover {
  background-size: contain !important;
}
</style>