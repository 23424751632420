<template>
  <div class="customerFields">
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          autocomplete="off"
          v-model="customer.name"
          name="name"
          label="Nachname Vorname *"
          :rules="nameRule"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          autocomplete="off"
          v-model="customer.street"
          name="street"
          label="Straße *"
          :rules="streetRule"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-autocomplete
          autocomplete="off"
          :items="postCodes"
          label="PLZ und Ort *"
          auto-select-first
          v-model="customer.plzCity"
          name="plzCity"
          :item-text="(item) => item.plz + ' - ' + item.name"
          return-object
          :rules="plzCityRule"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="customer.email"
          name="email"
          label="E-Mail"
          :rules="emailRule"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select
          :items="regionsSelect"
          name="region"
          v-model="customer.region"
          label="Region"
          clearable
        ></v-select>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="customer.phone"
          name="phone"
          label="Telefon"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-menu
          v-model="lastDatpickerOpen"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="customer.last_maintenance"
              name="last_maintenance"
              label="Letzte Wartung"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="customer.last_maintenance"
            @change="setnext_maintenance"
            @input="lastDatpickerOpen = false"
            :first-day-of-week="1"
            locale="de-de"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-select
          :items="maintenance_interval"
          name="maintenance_interval"
          v-model="customer.maintenance_interval"
          @change="setnext_maintenance"
          label="Wartungsintervall"
          item-text="text"
          item-value="days"
          clearable
        ></v-select>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-menu
          v-model="nextDatpickerOpen"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="customer.next_maintenance"
              name="next_maintenance"
              label="Nächste Wartung"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="customer.next_maintenance"
            @input="nextDatpickerOpen = false"
            :first-day-of-week="1"
            locale="de-de"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="6" sm="3" md="2">
        <v-checkbox
          v-model="customer.marked_black"
          name="marked_black"
          prepend-icon="mdi-star-circle"
          color="blue"
          value="1"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" sm="3" md="2">
        <v-checkbox
          v-model="customer.marked_red"
          name="marked_red"
          prepend-icon="mdi-alert-circle"
          color="red"
          value="1"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-textarea
          name="billing_address"
          label="Rechnungsadresse"
          v-model="customer.billing_address"
          rows="3"
          row-height="15"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea
          name="contact_facility_mgnt"
          v-model="customer.contact_facility_mgnt"
          label="Kontakt / Hausverwaltung"
          rows="3"
          row-height="15"
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-textarea
          name="note"
          v-model="customer.note"
          label="Notiz"
          rows="3"
          row-height="15"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import regions from "../regionen.json";
import postCodes from "../plz.json";
import maintenance_interval from "../wartungsintervall.json";

export default {
  name: "Customer",
  props: ["editCustomer"],
  data: () => ({
    regionsSelect: regions,
    postCodes: postCodes,
    maintenance_interval: maintenance_interval,
    lastDatpickerOpen: false,
    nextDatpickerOpen: false,
    customer: {
      name: "",
      street: "",
      plzCity: "",
      email: "",
      next_maintenance: "", //new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      last_maintenance: "",
      region: "",
      phone: "",
      maintenance_interval: "",
      marked_black: false,
      marked_red: false,
      billing_address: "",
      contact_facility_mgnt: "",
      note: "",
    },
    nameRule: [(v) => !!v || "Bitte Name angeben"],
    streetRule: [(v) => !!v || "Bitte Straße angeben"],
    plzCityRule: [(v) => !!v || "Bitte PLZ und Ort angeben"],
    emailRule: [
      (v) => !v || /.+@.+\..+/.test(v) || "E-mail Adresse fehlerhaft",
    ],
  }),
  methods: {
    setnext_maintenance(currentValue) {
      if (
        this.customer.last_maintenance &&
        this.customer.maintenance_interval
      ) {
        let lmd = new Date(this.customer.last_maintenance);
        lmd.setDate(lmd.getDate() + this.customer.maintenance_interval);
        this.customer.next_maintenance = lmd.toISOString().split("T")[0];
      }
    },
  },
  mounted() {
    if (this.editCustomer) {
      this.customer = this.editCustomer;
      if (this.editCustomer.postcode && this.editCustomer.city) {
        this.customer.plzCity =
          this.editCustomer.postcode + " - " + this.editCustomer.city;
        if (this.customer.maintenance_interval) {
          this.customer.maintenance_interval = this.maintenance_interval.find(
            (x) => x.days == this.customer.maintenance_interval
          ).days;
        }
      }
    }
  },
};
</script>
